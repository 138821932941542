import React, { ReactElement } from "react";
import styled from "styled-components";
import { CampaignStateType, CampaignStatusLabel } from "@bizchat/api-interface";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import Calendar from "@components/common/datepicker/Calendar.tsx";
import classNames from "classnames";

interface CampaignStatusProps {
  status: number;
  messages?: string;
}

export const CampaignStatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 1em 0;

  .status {
    background-color: #f8f8f8;
    padding: 0.2em 1em;
    border: 1px solid #ededed;
    border-radius: 20px;

    &.reject_status {
      background-color: #fd7878;
      color: white;
      border-color: transparent;
    }
  }

  .status_wrapper {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    align-items: center;
    gap: 10px;
  }
`;

export const CampaignStatus = (props: CampaignStatusProps): ReactElement => {
  const { status, messages } = props;

  const campaignStatus = (status: number): string => {
    switch (status) {
      case CampaignStateType.APPROVAL:
        return CampaignStatusLabel.APPROVAL;
      case CampaignStateType.APPROVAL_SUCCESS:
        return CampaignStatusLabel.APPROVAL_SUCCESS;
      case CampaignStateType.READY:
        return CampaignStatusLabel.READY;
      case CampaignStateType.CANCEL:
        return CampaignStatusLabel.CANCEL;
      case CampaignStateType.ING:
        return CampaignStatusLabel.ING;
      case CampaignStateType.STOP:
        return CampaignStatusLabel.STOP;
      case CampaignStateType.TERMINATE:
        return CampaignStatusLabel.TERMINATE;
      case CampaignStateType.REJECT:
        return CampaignStatusLabel.REJECT;
      case CampaignStateType.SELF_TERMINATE:
        return CampaignStatusLabel.SELF_TERMINATE;
      default:
        return "";
    }
  };

  return (
    <CampaignStatusContainer>
      <FlexBox
        $flexDirection={"row"}
        $gap={10}
        $justifyContent={"flex-end"}
        $alignItems={"baseline"}
      >
        <Typography $fontWeight={500} $fontSize={14}>
          발송 상태
        </Typography>
        <div className={"status_wrapper"}>
          <Typography
            as={"span"}
            $fontSize={14}
            className={classNames("status", {
              reject_status: status === CampaignStateType.REJECT,
            })}
          >
            {campaignStatus(status)}
          </Typography>
        </div>
      </FlexBox>

      {status === CampaignStateType.REJECT && (
        <FlexBox $flexDirection={"row"} $justifyContent={"flex-start"} $gap={8}>
          <Typography as={"span"} $fontSize={14}>
            반려 사유 :
          </Typography>
          <Typography $fontColor={"textRed000"} $fontSize={14}>
            {messages}
          </Typography>
        </FlexBox>
      )}
    </CampaignStatusContainer>
  );
};
