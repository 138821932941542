import React, { Fragment, ReactElement, useEffect } from "react";
import classNames from "classnames";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import {
  CampaignStatusLabel,
  CategoryFilterQueryType,
  GenerateStatus,
} from "@bizchat/api-interface";
import { CampaignStatusSelectBox } from "@components/common/select-box/CampaignStatusSelectBox.tsx";
import { FaFilter } from "react-icons/fa6";
import {
  LIFE_STAGE_SEG_CODE,
  RequestAtsList,
} from "@components/project/views/RequestView.tsx";
import { Option } from "@components/common/select-box/SelectBox.tsx";
import { Button } from "@components/common/button";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import { setCampaignId } from "@store/slices/campaignSlice.ts";
import {
  ModalCompType,
  ModalState,
  setModalOpen,
} from "@store/slices/modalSlice.ts";
import styled from "styled-components";
import { useOutletContext } from "react-router";
import { Toast } from "@components/common/toast/Toast.tsx";
import { campaignApprovalRequestApi } from "@apis/campaign";
import { Spinner } from "@components/common/spinner/Spinner.tsx";
import moment from "moment";
import { AxiosError } from "axios";
import { queryClient } from "@libs/tanstack/queryClient.ts";
import { reportQueryKey } from "@services/queries/keys";

interface RequestListHistoryRowProps {
  request: RequestAtsList;
  filterCodeList: string[];
  handleChangeCampaignStatus: (
    v: Option,
    atsId: string,
    campaignId: string,
  ) => void;
  refetch: () => void;
}

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const DateLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .date-label {
    font-weight: 500;
  }

  .date {
    color: ${({ theme }) => theme.colors.textGray200};
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  height: 77px;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  justify-content: center;
  border: 1px solid var(--borderColor2);
`;

export const RequestListHistoryRow = ({
  request,
  filterCodeList,
  handleChangeCampaignStatus,
  refetch,
}: RequestListHistoryRowProps): ReactElement => {
  const dispatch = useAppDispatch();
  const sendDate = useAppSelector((state) => state.requestStore.requestDate);
  const { id } = useOutletContext<{ id: string }>();

  const getFilterIndex = (filterQuery: CategoryFilterQueryType[]) => {
    const historyGeneralCode: string[] = filterQuery.map(({ code }) => code);
    const historyLifeCode: string[] = filterQuery
      .filter(({ code }) => code === LIFE_STAGE_SEG_CODE)
      .flatMap(({ data }) => data) as string[];
    const historyUsageCode: string[] = [];
    filterQuery.forEach(({ code, metaType, data }) => {
      if (!code) {
        if (Array.isArray(data)) {
          const list = data as { cat1: string; cat2: string; cat3: string }[];
          list.forEach(({ cat1, cat2, cat3 }) => {
            historyUsageCode.push(`${metaType}-${cat1}-${cat2}-${cat3}`);
          });
        }
      }
    });
    const historyCode = [
      ...historyGeneralCode,
      ...historyLifeCode,
      ...historyUsageCode,
    ];
    const index: number[] = [];
    historyCode.forEach((code) => {
      if (filterCodeList.includes(code)) {
        index.push(filterCodeList.indexOf(code) + 1);
      }
    });
    index.sort((a, b) => {
      if (a > b) return 1;
      else if (a === b) return 0;
      else return -1;
    });
    return index.join(",");
  };

  const handleClickShowCampaign = async (campaignId: string) => {
    // 캠페인 아이디 등록
    dispatch(setCampaignId(campaignId));
    // modal
    const modalState: ModalState = {
      type: ModalCompType.CAMPAIGN_DETAIL,
      modalType: "fade",
      isOpen: true,
    };
    dispatch(setModalOpen(modalState));

    await queryClient.invalidateQueries({
      queryKey: [
        reportQueryKey.messageSendCount,
        reportQueryKey.reactionTotalCount,
        reportQueryKey.reactionCount,
      ],
    });
  };

  /**
   * @description 테스트 발송 모달 열기
   * @param {string} campaignId
   */
  const handleClickTestSend = (campaignId: string) => {
    // 캠페인 아이디 등록
    dispatch(setCampaignId(campaignId));
    // modal
    const modalState: ModalState = {
      type: ModalCompType.SEND_TEST_CAMPAIGN,
      modalType: "fade",
      isOpen: true,
    };
    dispatch(setModalOpen(modalState));
  };

  /**
   * @description 캠페인 상태가 반려일 경우 버튼이 보여지며
   * 클릭 시 캠페인 수정 화면으로 이동
   * @param {string} campaignId
   */
  const handleClickCampaignEditForm = async (
    campaignId: string,
  ): Promise<void> => {
    const alertButton = confirm("캠페인 승인을 요청 하시겠습니까?");
    if (!alertButton) return;

    try {
      const body = {
        targetProject: id,
        campaignId,
        sendDate: moment(sendDate).format("YYYY-MM-DD, HH:mm:ss"),
      };
      const result = await campaignApprovalRequestApi(body);
      if (result) Toast.success("승인 요청 되었습니다.");
      refetch();
    } catch (e) {
      const error = e instanceof AxiosError;
      if (error) {
        const axiosError = e as unknown as AxiosError;
        if (axiosError.response) {
          const { message } = axiosError.response.data as AxiosError;
          Toast.error(message);
        }
      } else {
        console.log(e);
        Toast.error("캠페인 승인 요청에 실패하였습니다.");
      }
    }
  };

  useEffect(() => {
    return () => {
      // 언마운트 시 모달 닫기
      dispatch(setCampaignId(""));
      const modalState: ModalState = {
        type: ModalCompType.SEND_TEST_CAMPAIGN,
        modalType: "fade",
        isOpen: false,
      };
      dispatch(setModalOpen(modalState));
    };
  }, []);

  return (
    <div
      className={classNames("tr", "history-tr")}
      key={`history-${request._id}`}
    >
      <div className={"td"} />
      <div className={"td"}>
        {request.historyList.map(
          (
            {
              filterQuery,
              campaignStatus,
              campaignId,
              campaignExpectedSendDate,
              campaignRealSendDate,
              sendDate,
              totalSendCount,
              status,
            },
            historyIndex,
          ) => (
            <Fragment key={`history-${campaignId}`}>
              {status === GenerateStatus.CREATING && (
                <SpinnerContainer>
                  <Spinner />
                </SpinnerContainer>
              )}
              <FlexBox
                $justifyContent={"space-between"}
                className={classNames("history-item", {
                  reject: campaignStatus === CampaignStatusLabel.REJECT,
                })}
                $flexDirection={"row"}
              >
                <FlexBox
                  $flexDirection={"row"}
                  $justifyContent={"space-between"}
                >
                  <FlexBox
                    $flexDirection={"row"}
                    $justifyContent={"flex-start"}
                    $gap={10}
                    style={{ flexWrap: "wrap" }}
                  >
                    <Typography
                      as={"span"}
                      $fontWeight={600}
                      $fontSize={14}
                      className={"status_label_number"}
                    >
                      {historyIndex + 1}.{" "}
                    </Typography>
                    {/* 진행중 | 승인 완료 | 발송 준비 가 아니라면 상태값만 표기 */}
                    {campaignStatus !== CampaignStatusLabel.ING &&
                      campaignStatus !== CampaignStatusLabel.APPROVAL_SUCCESS &&
                      campaignStatus !== CampaignStatusLabel.READY && (
                        <Typography
                          as={"span"}
                          $fontWeight={300}
                          $fontSize={14}
                          className={classNames("status_label", {
                            reject_label:
                              campaignStatus === CampaignStatusLabel.REJECT,
                          })}
                        >
                          {campaignStatus}
                        </Typography>
                      )}
                    {/* 진행중 일 경우 캠페인 중단 */}
                    {campaignStatus === CampaignStatusLabel.ING && (
                      <CampaignStatusSelectBox
                        placeholder={campaignStatus}
                        options={[
                          {
                            label: CampaignStatusLabel.STOP,
                            value: CampaignStatusLabel.STOP,
                          },
                        ]}
                        onChange={(v) =>
                          handleChangeCampaignStatus(v, request._id, campaignId)
                        }
                      />
                    )}
                    {/* 승인 완료 || 발송 준비 일 경우 캠페인 취소 */}
                    {(campaignStatus === CampaignStatusLabel.READY ||
                      campaignStatus ===
                        CampaignStatusLabel.APPROVAL_SUCCESS) && (
                      <CampaignStatusSelectBox
                        placeholder={campaignStatus}
                        options={[
                          {
                            label: CampaignStatusLabel.CANCEL,
                            value: CampaignStatusLabel.CANCEL,
                          },
                        ]}
                        onChange={(v) =>
                          handleChangeCampaignStatus(v, request._id, campaignId)
                        }
                      />
                    )}
                    <Typography
                      as={"span"}
                      $fontWeight={300}
                      $fontSize={14}
                      className={"number_label"}
                    >
                      {totalSendCount.toLocaleString()} 건
                    </Typography>
                    <DateLabelContainer>
                      <Typography>
                        <span className={"date-label"}>요청 시간 : </span>
                        <span className={"date"}>
                          {moment(sendDate).format("YYYY.MM.DD HH:mm:ss")}
                        </span>
                      </Typography>
                      {campaignExpectedSendDate && (
                        <Typography>
                          <span className={"date-label"}>발송 일정 : </span>
                          <span className={"date"}>
                            {moment(campaignExpectedSendDate).format(
                              "YYYY.MM.DD HH:mm:ss",
                            )}
                          </span>
                        </Typography>
                      )}
                      {campaignRealSendDate && (
                        <Typography>
                          <span className={"date-label"}>발송일 : </span>
                          <span className={"date"}>
                            {moment(campaignRealSendDate).format(
                              "YYYY.MM.DD HH:mm:ss",
                            )}
                          </span>
                        </Typography>
                      )}
                    </DateLabelContainer>

                    <FaFilter />
                    <Typography as={"span"} $fontWeight={300}>
                      {0 < filterQuery.length && "필터"}
                      {0 < filterQuery.length && getFilterIndex(filterQuery)}
                    </Typography>
                  </FlexBox>

                  <ButtonGroup>
                    {campaignStatus === CampaignStatusLabel.REJECT && (
                      <Button
                        variant={"gray100"}
                        onClick={() => handleClickCampaignEditForm(campaignId)}
                      >
                        <Typography $fontColor={"textGray100"}>
                          승인요청
                        </Typography>
                      </Button>
                    )}

                    {campaignStatus !== CampaignStatusLabel.REJECT &&
                      campaignStatus !== CampaignStatusLabel.TERMINATE &&
                      campaignStatus !== CampaignStatusLabel.SELF_TERMINATE &&
                      campaignStatus !== CampaignStatusLabel.CANCEL && (
                        <Button
                          variant={"gray100"}
                          onClick={() => handleClickTestSend(campaignId)}
                        >
                          <Typography $fontColor={"textGray100"}>
                            테스트 발송
                          </Typography>
                        </Button>
                      )}
                    <Button onClick={() => handleClickShowCampaign(campaignId)}>
                      <Typography $fontColor={"textWhite"}>
                        캠페인 보기
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </FlexBox>
              </FlexBox>
            </Fragment>
          ),
        )}
      </div>
    </div>
  );
};
