import React, { ReactElement } from "react";
import { Card } from "@components/common/card/Card.tsx";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Table } from "@components/common/table/Table.tsx";
import ProgressBar from "@ramonak/react-progress-bar";
import styled from "styled-components";
import { DonutsChart } from "@components/common/charts/DonutsChart.tsx";
import { DonutChart, SendSuccessCount } from "@bizchat/api-interface";
import { Spinner } from "@components/common/spinner/Spinner.tsx";

const CompletedCountRewardSectionLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-gap: 60px;

  .content-container {
    padding: 2rem;
  }

  .donut-container {
    display: grid;
    height: 100%;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;

    .legend-layout {
      display: grid;
      align-items: center;
      grid-template-columns: 30px 80px 100px;
    }
  }
  @media screen and (max-width: 1244px) {
    grid-template-columns: none;
    grid-gap: 38px;
  }
`;

const TableContainer = styled.article`
  margin-top: 44px;

  table {
    tbody {
      tr {
        td {
          padding: 0.5rem 2rem;
          border: 1px solid ${({ theme }) => theme.colors.borderColor2};
        }
        td:first-child {
          text-align: start;
        }
        td:last-child {
          text-align: end;
        }
      }

      tr:last-child {
        background-color: ${({ theme }) => theme.colors.purple300};
      }
    }
  }
`;

const Divider = styled.div`
  border: 1px #d1d1d1 solid;
`;

const LegendBox = styled.div<{ $backgroundColor: string }>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${(props) => props.$backgroundColor};
`;

export interface SentMessageAndRewardSectionProps {
  sentMessageCountInfo: SendSuccessCount[];
  donutChartData: DonutChart[] | undefined;
  creating: boolean;
}

export const SentMessageAndRewardSection = ({
  sentMessageCountInfo,
  donutChartData,
  creating,
}: SentMessageAndRewardSectionProps): ReactElement => {
  const donutColors = ["#9EE5D7", "#403D4E", "#A587FF"];

  return (
    <Card className={"mt-20"}>
      <CompletedCountRewardSectionLayout>
        {sentMessageCountInfo.length === 0 ? (
          <Typography>데이터가 없습니다.</Typography>
        ) : (
          <>
            {creating ? (
              <FlexBox $gap={10}>
                <Spinner size={24} color={"#403D4e"} />
                <Typography>데이터를 조회하고 있습니다.</Typography>
              </FlexBox>
            ) : (
              <section>
                <Typography variant={"h2"} $fontSize={22} $fontWeight={600}>
                  문자 발송 성공 건수
                </Typography>
                <div className={"content-container"}>
                  <FlexBox
                    $flexDirection={"row"}
                    $justifyContent={"space-between"}
                    className={"mb-10"}
                  >
                    <FlexBox $alignItems={"flex-start"}>
                      <Typography
                        $fontSize={30}
                        $fontWeight={600}
                        className={"mb-10"}
                      >
                        {sentMessageCountInfo[0].rcsMessagePercentage}%
                      </Typography>
                      <Typography $fontColor={"textGray100"}>
                        RCS 발송 성공 건수
                      </Typography>
                    </FlexBox>
                    <FlexBox $alignItems={"flex-end"}>
                      <Typography
                        $fontSize={30}
                        $fontWeight={600}
                        className={"mb-10"}
                      >
                        {sentMessageCountInfo[0].vmgMessagePercentage}%
                      </Typography>
                      <Typography $fontColor={"textGray100"}>
                        일반문자 발송 성공 건수
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                  <ProgressBar
                    completed={sentMessageCountInfo[0].rcsMessagePercentage}
                    isLabelVisible={false}
                    bgColor={"#A588FF"}
                  />
                  <TableContainer>
                    <Table
                      bodyChildren={
                        <>
                          <tr>
                            <td className={"w-65"}>
                              <Typography>RCS 발송 성공 건수</Typography>
                            </td>
                            <td>
                              {sentMessageCountInfo[0].rcsMessageTotal.toLocaleString()}
                            </td>
                          </tr>
                          <tr>
                            <td className={"w-65"}>
                              <Typography>일반문자 발송 성공 건수</Typography>
                            </td>
                            <td>
                              {sentMessageCountInfo[0].vmgMessageTotal.toLocaleString()}
                            </td>
                          </tr>
                          <tr>
                            <td className={"w-65"}>
                              <Typography $fontWeight={600}>
                                전체 발송 성공 건수
                              </Typography>
                            </td>
                            <td>
                              {sentMessageCountInfo[0].messageTotal.toLocaleString()}
                            </td>
                          </tr>
                        </>
                      }
                    />
                  </TableContainer>
                </div>
              </section>
            )}
          </>
        )}

        <Divider />

        {/*TODO 데이터 없을 경우 체크*/}
        <section>
          <Typography variant={"h2"} $fontSize={22} $fontWeight={600}>
            완료자 리워드 선호도
          </Typography>
          {!donutChartData ? (
            <div>데이터 없음</div>
          ) : (
            <div className={"donut-container"}>
              <DonutsChart data={donutChartData} colors={donutColors} />
              <FlexBox $gap={20}>
                {donutChartData.map(({ name, data }, index) => (
                  <div key={`donut-legend-${name}`} className={"legend-layout"}>
                    <LegendBox $backgroundColor={donutColors[index]} />
                    <Typography as={"span"} $fontColor={"textGray100"}>
                      {name}
                    </Typography>
                    <Typography as={"span"} $fontWeight={700} $fontSize={24}>
                      {data}%
                    </Typography>
                  </div>
                ))}
              </FlexBox>
            </div>
          )}
        </section>
      </CompletedCountRewardSectionLayout>
    </Card>
  );
};
