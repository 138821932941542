import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import {
  AtsDetails,
  AtsListType,
  CampaignApprovalRequestBody,
  CampaignApprovalSingleRequestBody,
  CampaignDetails,
  CampaignSendResultState,
  CampaignStatusLabel,
  CreateCampaignBody,
} from "@bizchat/api-interface";
import {
  AxiosResponseData,
  axiosResponseData,
} from "@libs/axios/axiosResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";

const CAMPAIGN = "/campaign";

export const requestListApi = async (
  projectId: string,
): Promise<AtsListType[] | null> => {
  const url = `${CAMPAIGN}/ats/${projectId}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<AtsListType[]>>
  >(url);
  return axiosResponseData<AtsListType[]>(data);
};

export const createCampaignApi = async (
  targetProject: string,
  body: CreateCampaignBody[],
) => {
  const url = `${CAMPAIGN}/generate/${targetProject}`;
  const { data } = await axiosInstance.post(url, body);
  return data;
};

/**
 * @description 반려된 캠페인을 재 승인 요청 한다.
 */
export const campaignApprovalRequestApi = async (
  body: CampaignApprovalSingleRequestBody,
) => {
  const url = `${CAMPAIGN}/approval/single`;
  const { data } = await axiosInstance.post(url, body);
  return data;
};

export const campaignApprovalRequestAllApi = async (
  body: CampaignApprovalRequestBody,
) => {
  const url = `${CAMPAIGN}/approval`;
  const { data } = await axiosInstance.post(url, body);
  return data;
};

export const updateHistoryCampaignStatus = async (body: {
  targetProject: string;
  campaignId: string;
  campaignStatus: CampaignStatusLabel;
}) => {
  const url = `${CAMPAIGN}/terminate`;
  const { data } = await axiosInstance.patch(url, body);
  return data;
};

/**
 * @description 캠페인 상세 조회 (발송요청서 -> 캠페인보기)
 * @param campaignId
 */
export const getCampaignApi = async (campaignId: string) => {
  if (!campaignId) return null;
  const url = `${CAMPAIGN}/${campaignId}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<
      AxiosResponseData<{
        ats: AtsDetails;
        campaign: CampaignDetails;
      }>
    >
  >(url);
  return axiosResponseData<{
    ats: AtsDetails;
    campaign: CampaignDetails;
  }>(data);
};

export const getCampaignSendResultApi = async (
  campaignId: string,
  targetProject: string,
): Promise<CampaignSendResultState | null> => {
  if (!campaignId) return null;
  const url = `${CAMPAIGN}/send/result/${campaignId}/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<CampaignSendResultState>>
  >(url);
  return axiosResponseData<CampaignSendResultState>(data);
};

export const campaignCancelApi = async (campaignId: string) => {
  const url = `${CAMPAIGN}/cancel/${campaignId}`;
  const { data } = await axiosInstance.patch(url);
  return data;
};

export const createCampaignStatistics = async (targetProject: string) => {
  const url = `${CAMPAIGN}/statistics/${targetProject}`;
  const { data } = await axiosInstance.get(url);
  return data;
};
