import styled from "styled-components";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import { QuotaDataDownloadButton } from "@components/report/download/QuotaDataDownloadButton.tsx";
import { QuotaTable } from "@components/report/QuotaTable.tsx";
import { Quota } from "@bizchat/api-interface";
import { ReactElement } from "react";

const TableContainer = styled.div`
  .download-btn {
    width: 97px;
  }

  table {
    th {
      vertical-align: middle;
    }
    tbody {
      tr {
        border: 1px solid ${({ theme }) => theme.colors.borderColor1};
      }
      tr:nth-child(1) {
        background-color: ${({ theme }) => theme.colors.purple300};
        span {
          font-weight: 600;
        }
      }
      tr td:nth-child(2) {
        span {
          font-weight: 600;
          color: #2e5eb0;
        }
      }
    }
  }
`;

interface QuotaTableSectionProps {
  statusData: Quota[];
  targetProject: string;
}

export const QuotaTableSection = ({
  statusData,
  targetProject,
}: QuotaTableSectionProps): ReactElement => {
  return (
    <TableContainer>
      <FlexBox $alignItems={"flex-start"} $gap={20}>
        <FlexBox $flexDirection={"row"} $justifyContent={"space-between"}>
          <Typography $fontSize={17} $fontWeight={700}>
            현황
          </Typography>
          <QuotaDataDownloadButton targetProject={targetProject} />
        </FlexBox>
        <QuotaTable data={statusData} />
      </FlexBox>
    </TableContainer>
  );
};
