import styled from "styled-components";

export const Button = styled.button`
  width: 12em;
  max-height: 3.5em;
  height: auto;
  padding: 0.4em;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:not(:disabled):hover {
    filter: brightness(95%);
  }

  &.primary {
    background-color: ${({ theme }) => theme.colors.purple000};

    span {
      color: #fff;
    }
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.colors.white100};

    span {
      color: var(--textGray200);
    }
  }

  &.black000 {
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors.black000};
    color: ${({ theme }) => theme.colors.white000};

    span {
      color: ${({ theme }) => theme.colors.white000};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.white100};
    }
  }

  &.gray000 {
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors.gray100};
    color: ${({ theme }) => theme.colors.black400};

    span {
      color: ${({ theme }) => theme.colors.black400};
    }

    &:disabled {
      cursor: default;
      background-color: ${({ theme }) => theme.colors.white100};
      border-color: #eaeaea;

      span {
        color: var(--textGray200);
      }
    }
  }

  &.gray100 {
    border-color: ${({ theme }) => theme.colors.borderColor2};
    background-color: ${({ theme }) => theme.colors.white100};
    span {
      color: #cccccc;
    }
  }

  &.red000 {
    background-color: ${({ theme }) => theme.colors.red000};

    span {
      color: #fbfbfb;
    }
  }

  &.white000 {
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors.white000};
    color: ${({ theme }) => theme.colors.black000};

    &:disabled {
      background-color: ${({ theme }) => theme.colors.gray100};
    }
  }

  &.icon {
    background-color: transparent;
    width: fit-content;
    height: auto;
    box-shadow: inherit;
    padding: 0;
  }

  &.second {
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors.second};
    color: ${({ theme }) => theme.colors.black000};

    &:disabled {
      background-color: ${({ theme }) => theme.colors.white100};
    }
  }

  &.icon {
    background-color: transparent;
    width: fit-content;
    min-width: fit-content;
    height: auto;
    box-shadow: inherit;
    padding: 0;
  }
`;
