import { ReactionCount } from "@bizchat/api-interface";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { ReactionRatioBox } from "@components/report/ReactionRatioBox.tsx";
import styled from "styled-components";
import { Spinner } from "@components/common/spinner/Spinner.tsx";

interface MessageReactionRatioProps {
  data: ReactionCount[];
  creating: boolean;
}

export const MessageReactionRatioLayout = styled.div``;

export const MessageReactionRatioContainer = styled.div`
  margin: 40px auto;

  @media screen and (max-width: 1244px) {
    .status-bar-container {
      flex-direction: column;
    }
  }
`;

export const MessageReactionRatio = ({
  data,
  creating,
}: MessageReactionRatioProps): ReactElement => {
  if (creating)
    return (
      <MessageReactionRatioLayout>
        <FlexBox $gap={10}>
          <Spinner size={24} color={"#403D4e"} />
          <Typography>데이터를 조회하고 있습니다.</Typography>
        </FlexBox>
      </MessageReactionRatioLayout>
    );

  return (
    <MessageReactionRatioLayout>
      {data.length === 0 ? (
        <Typography>데이터가 없습니다.</Typography>
      ) : (
        <FlexBox $alignItems={"flex-start"}>
          <Typography variant={"h2"} $fontSize={22} $fontWeight={600}>
            문자 유형별 반응률 / 확인률 (%)
          </Typography>
          <MessageReactionRatioContainer>
            <FlexBox
              $flexDirection={"row"}
              className={classNames("mt-20", "status-bar-container")}
              $gap={20}
            >
              <ReactionRatioBox
                title={"RCS 전체 반응률(%)"}
                description={"RCS 전체 반응수 / RCS 발송 성공 건수"}
                messageLabel={"RCS 발송 성공"}
                messageTotal={data[0].rcsMessageTotal}
                reactionLabel={"RCS 반응 고객"}
                reactionTotal={data[0].rcsReactionTotal}
                variant={"purple"}
              />

              <ReactionRatioBox
                title={"RCS 전체 확인률(%)"}
                description={"RCS 확인자 수 / RCS 발송 성공 건수"}
                messageLabel={"RCS 발송 성공"}
                messageTotal={data[0].rcsMessageTotal}
                reactionLabel={"RCS 확인 고객"}
                reactionTotal={data[0].rcsMessageRecvTotal}
                variant={"gray"}
              />

              <ReactionRatioBox
                title={"일반문자 전체 반응률(%)"}
                description={"일반문자 전체 반응수 / 일반문자 발송 성공 건수"}
                messageLabel={"일반문자 발송 성공"}
                messageTotal={data[0].vmgMessageTotal}
                reactionLabel={"일반문자 반응 고객"}
                reactionTotal={data[0].vmgReactionTotal}
                variant={"mint"}
              />
            </FlexBox>
          </MessageReactionRatioContainer>
        </FlexBox>
      )}
    </MessageReactionRatioLayout>
  );
};
