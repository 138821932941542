import React, { ReactElement } from "react";
import { ReportInfoSection } from "@components/report/ReportInfoSection.tsx";
import { useNavigate, useOutletContext } from "react-router";
import {
  useDailyParticipationQuery,
  useMessageSendCountQuery,
  usePreferenceRewardQuery,
  useReactionCountQuery,
  useReactionTotalCountQuery,
  useReportProjectQuery,
  useReportQuotaCountQuery,
  useTimeParticipationQuery,
} from "@services/queries/hooks/useReportQuery.ts";
import { SpinnerBox } from "@components/common/spinner/SpinnerBox.tsx";
import { Button } from "@components/common/button";
import { FaArrowLeft } from "react-icons/fa6";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { LineChartSection } from "@components/report/LineChartSection.tsx";
import { Card } from "@components/common/card/Card.tsx";
import { SentMessageAndRewardSection } from "@components/report/SentMessageAndRewardSection.tsx";
import { AllReactionSection } from "@components/report/AllReactionSection.tsx";
import { MessageReactionRatio } from "@components/report/MessageReactionRatio.tsx";
import { GenerateStatus } from "@bizchat/api-interface";
import { Spinner } from "@components/common/spinner/Spinner.tsx";
import { EventHandler } from "@libs/event-source-polyfill/EventHandler.tsx";
import { useAuth } from "@providers/AuthProvider.tsx";
import { queryClient } from "@libs/tanstack/queryClient.ts";
import { reportQueryKey } from "@services/queries/keys";
import { Toast } from "@components/common/toast/Toast.tsx";

const data = {
  rewardData: {
    data: [
      {
        name: "문화상품권",
        data: 37,
      },
      {
        name: "OK캐시백",
        data: 63,
      },
      {
        name: "네이버페이",
        data: 0,
      },
    ],
  },
};

export const ReportView = (): ReactElement => {
  const { id } = useOutletContext<{ id: string }>();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const reportProjectInfoQuery = useReportProjectQuery(id);
  const reportQuotaCountQuery = useReportQuotaCountQuery(id);
  const dailyParticipationQuery = useDailyParticipationQuery(id);
  const timeParticipationQuery = useTimeParticipationQuery(id);
  const preferenceRewardQuery = usePreferenceRewardQuery(id);
  const messageSuccessQuery = useMessageSendCountQuery(id);
  const reactionTotalCountQuery = useReactionTotalCountQuery(id);
  const reactionCountQuery = useReactionCountQuery(id);

  if (reportQuotaCountQuery.isLoading && reportProjectInfoQuery.isLoading)
    return <SpinnerBox />;

  const project = reportProjectInfoQuery?.data || {
    campaignTitle: "",
    startDate: null,
    endDate: null,
    statisticsGenerate: GenerateStatus.READY,
    statisticsDate: null,
  };

  const creating = project?.statisticsGenerate === GenerateStatus.CREATING;

  const reportData = async (event: any) => {
    await queryClient.invalidateQueries({
      queryKey: [reportQueryKey.projectInfo],
    });
    await queryClient.invalidateQueries({
      queryKey: [reportQueryKey.reactionCount],
    });
    await queryClient.invalidateQueries({
      queryKey: [reportQueryKey.reactionTotalCount],
    });
    await queryClient.invalidateQueries({
      queryKey: [reportQueryKey.messageSendCount],
    });
    await queryClient.invalidateQueries({
      queryKey: [reportQueryKey.dailyParticipation],
    });
    Toast.success("반응률 조회 되었습니다.");
  };

  const eventListeners = [
    { eventName: "statics-test", callback: (event: any) => reportData(event) },
  ];

  return (
    <>
      <ReportInfoSection
        project={project}
        statusData={reportQuotaCountQuery.data || []}
        targetProject={id}
      />

      {dailyParticipationQuery.isLoading ? (
        <Card className={"mt-20"}>
          <Spinner size={24} color={"#403D4e"} />
        </Card>
      ) : (
        <LineChartSection
          title={"일별 반응수"}
          chartData={dailyParticipationQuery.data}
        />
      )}

      {timeParticipationQuery.isLoading ? (
        <Card className={"mt-20"}>
          <Spinner size={24} color={"#403D4e"} />
        </Card>
      ) : (
        <LineChartSection
          title={"시간대별 반응수"}
          chartData={timeParticipationQuery.data}
        />
      )}

      <SentMessageAndRewardSection
        sentMessageCountInfo={messageSuccessQuery.data || []}
        donutChartData={preferenceRewardQuery.data}
        creating={creating}
      />

      <AllReactionSection
        sentMessageCountInfo={reactionTotalCountQuery.data || []}
        rewardData={data.rewardData}
        creating={creating}
      />

      <Card className={"mt-20"}>
        <MessageReactionRatio
          creating={creating}
          data={reactionCountQuery.data || []}
        />

        {/*<Line />*/}

        {/*<QuotaSection />*/}
      </Card>

      <FlexBox
        $gap={20}
        $flexDirection={"row"}
        $justifyContent={"flex-end"}
        className={"mt-30"}
      >
        <Button
          aria-label={"previous-button"}
          type={"button"}
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft color={"#fff"} size={20} />
        </Button>
      </FlexBox>

      <EventHandler
        id={id}
        token={getToken()}
        eventListeners={eventListeners}
      />
    </>
  );
};
