import styled from "styled-components";

export const ProjectInfoLayout = styled.section`
  .spinner {
    display: inline-block;
    &.active {
      animation: spin 1s linear infinite;
    }
  }

  .create_button {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
    border: 1px solid transparent;
    padding: 0.4em 0.8em;
    border-radius: 4px;
    min-height: 36px;
    height: auto;
    background-color: #ffffff;

    &:hover {
      border-color: #ededed;
    }

    &:disabled {
      background-color: #ededed;
      cursor: default;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
